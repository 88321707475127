<template>
  <h3>你是谁</h3>
</template>

<script>
export default {
  name : "readpage"
}
</script>

<style>

</style>